import { createRouter, createWebHashHistory } from 'vue-router'
import { h, resolveComponent } from 'vue'
import { useStore } from 'vuex'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'TestLogin',
    redirect: '/pages/login',
    component: DefaultLayout,
  },
  {
    path: '/dashboard',
    name: 'Start',
    meta: {
      requiresAuth: true,
    },
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/start',
        name: 'Start',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/Start.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/Start.vue'),
      },
      {
        path: '/dev/working',
        name: 'W trakcie tworzenia',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/Working.vue'),
      },
      {
        path: '/jmp/service',
        name: 'Protokół serwisowy',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/service.vue'),
      },
      {
        path: '/jmp/users',
        name: 'Zarządzanie dostępem użytkowników',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/users.vue'),
      },
      {
        path: '/jmp/changePassword',
        name: 'Zmiana hasła użytkownika',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/changePass.vue'),
      },
      {
        path: '/jmp/parkMaszynowyAll',
        name: 'Wszystkie pojazdy (wyszukiwarka)',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowyAll.vue'),
      },
      {
        path: '/jmp/parkMaszynowyKlienci',
        name: 'Filtrowanie klientów',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowyKlienci.vue'),
      },
      {
        path: '/jmp/parkMaszynowyL',
        name: 'Lista pojazdów sprzedanych',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowyL.vue'),
      },
      {
        path: '/jmp/parkMaszynowyW',
        name: 'Lista pojazdów wypożyczonych/podstawionych',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowyW.vue'),
      },
      {
        path: '/jmp/parkMaszynowy',
        name: 'sprzęt na stanie',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowy.vue'),
      },
      {
        path: '/jmp/zleceniaSerwisowe',
        name: 'zlecenia serwisowe',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/zleceniaSerwisowe.vue'),
      },
      {
        path: '/jmp/wNaprawie',
        name: 'sprzęt w naprawie',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/wNaprawie.vue'),
      },
      {
        path: '/jmp/zleceniaHandloweSprzedaz',
        name: 'zlecenia handlowe',
        meta: {
          active: false,
        },
        children: [
          {
            path: '',
            name: 'sprzedaż',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import('@/views/jmp/zleceniaHandloweSprzedaz.vue'),
          },
          {
            path: '/jmp/zleceniaHandloweWynajem',
            name: 'wynajem',
            meta: {
              active: false,
            },
            children: [
              {
                path: '',
                //name: 'wynajem',
                meta: {
                  requiresAuth: true,
                  active: false
                },
                component: () =>
                  import('@/views/jmp/zleceniaHandloweWynajem.vue'),
              },
              {
                path: 'form/:id',
                name: 'ZleceniaHandloweWynajemForm',
                meta: {
                  requiresAuth: true,
                  active: true
                },
                component: () =>
                  import('@/views/jmp/zleceniaHandloweWynajemForm.vue'),
              }
            ]
          },
          {
            path: '/jmp/zleceniaHandloweUsluga',
            name: 'usłsuga',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import('@/views/jmp/zleceniaHandloweUsluga.vue'),
          },
        ]
      },
      {
        path: '',
        name: 'zlecenia napraw',
        meta: {
         // active: true,
        },
        children: [
          {
            path: '/jmp/zleceniaWarsztatowe',
            name: 'zgłoszenia',
            children: [
              {
                path: '',
              //  name: 'zgłoszenia',
                meta: {
                  requiresAuth: true,
                },
                component: () =>
                  import('@/views/jmp/zleceniaWarsztatowe.vue'),
              },
              {
                path: 'serwisMobilny/:id',
                name: 'serwis mobilny',
                meta: {
                  requiresAuth: true,
                  active: true                
                },
                component: () =>
                  import('@/views/jmp/zlecenieMobilne.vue'),
                children: [],
              },
              {
                path: 'serwisStacjonarny/:id',
                name: 'zlecenie serwisowe',
                meta: {
                  requiresAuth: true,
                  active: true
                },
                component: () =>
                  import('@/views/jmp/zlecenieStacjonarne.vue'),
                children: [],
              },
            ]
          },
          {
            path: '/jmp/zleceniaNaprawMobilne',
            name: 'zlecenia mobilne',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/zleceniaNaprawMobilne.vue'),
            children: [],
          },
          {
            path: '/jmp/zleceniaNaprawWarsztatowe',
            name: 'zlecenia warsztatowe',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/zleceniaNaprawWarsztatowe.vue'),
            children: [],
          },
          {
            path: '/jmp/zleceniaNaprawWynajem',
            name: 'zlecenia wynajem',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/zleceniaNaprawWynajem.vue'),
            children: [],
          }
        ]
      },
      {
        path: '/jmp/zleceniaCzesci',
        name: 'baza części do zleceń',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/zleceniaCzesci.vue'),
      },
      {
        path: '/jmp/osprzetDoMaszyn',
        name: 'osprzęt do maszyn',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/osprzetDoMaszyn.vue'),
      },
      {
        path: '/jmp/raportPrzeglady',
        name: 'raport przeglądów serwisowych',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/raportPrzeglady.vue'),
      },
      {
        path: '/jmp/raportBadania',
        name: 'raport badań',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/raportBadania.vue'),
      },
      {
        path: '/jmp/raportWynajem',
        name: 'raport_wynajem',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/raportWynajem.vue'),
      },
      {
        path: '/jmp/araportWynajemAll',
        name: 'raport_wszystkich_wynajmów',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/raportWynajemAll.vue'),
      },
      {
        path: '/jmp/listaCzynnosci',
        name: 'listy kontrolne',
        children: [
          {
            path: '',
            name: 'listy kontrolne',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import('@/views/jmp/pytania.vue'),
          },
          {
            path: 'CzynnoscEdycja/:id',
            name: 'edycja listy kontrolnej',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/pytaniaPozycje.vue'),
            children: [],
          }
        ]
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },

    ],

  },

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  let store = useStore()
  if (to.meta.requiresAuth && store.state.jwt === '') {
    next("/pages/login");
  } else {
    next();
  }
});

export default router
